export default {  
    world_moment: '饶了地球一圈，还是没有找到您要的页面', 
    AI_Assistant:'AI 助手应用中心',
    use:'使用',
    remove:'移除',
    Add_application:'添加应用',
    Failed_application:'获取应用失败',
    Add_application:'添加应用',
    Remove_App:'移除应用',
    successed:'成功',
    failed:'失败',
    Export_PDF_document:'导出PDF文档',
    Failed_history:'加载聊天记录失败',
    Loading_fail:'加载会失败',
    Yahei:'微软雅黑',
    Enter_title:'请输入会话标题',
    edit:'编辑',
    Delete_Session:'删除会话',
    Account_information:'账户信息',
    Clear_sessions:'清除所有会话',
    log_off:'注销',
    model:'模型',
    Create_conversation:'新建对话',
    Export_Session:'导出会话',
    Stop_generating:'停止生成',
    regeneration:'regeneration',
    Press_line:'按 Enter 键发送消息，使用 Ctrl + Enter 换行',
    Website_Announcement:'网站公告',
    ChatGPT_Assistant:'ChatGPT-智能助手',
    Failed_configuration:'获取系统配置失败',
    Replicating_Success:'复制成功',
    copy_failed:'复制失败',
    Failed_role:'获取聊天角色失败',
    Failed_model:'加载模型失败',
    Failed_session_list:' 加载会话列表失败',
    Please_title:'请输入会话标题',
    The_ID:'对话 ID 为空，请刷新页面再试',
    operation_failed:'操作失败',
    Conversation_successful:'对话连接成功',
    AI_answering:'AI 正在作答中，请稍后...',
    session_history_records:'确认要清空所有的会话历史记录吗?',
    Operation_prompt:'操作提示',
    confirm:'确认',
    cancellation:'取消',
    Operation_successful:'操作成功',
    Operation_failed:'操作失败',
    Unregistration_failed:'注销失败',
    Failed_history:'加载聊天记录失败',
    Regenerate_questions:'重新生成上述问题的答案',
    select_session:'请先选中一个会话',
    Creative_Center:'创作中心',
    Picture_quality:'图片质量',
    Vividness_makes_model:'生动使模型倾向于生成超真实和戏剧性的图像',
    Please_enter_directly:'请在此输入绘画提示词，系统会自动翻译中文提示词，高手请直接输入英文提示词',
    Consumption_per_drawing:'每次绘图消耗',
    computing_power:'算力',
    currently_available:'当前可用',
    The_queued:'任务正在排队中',
    Generate_Now:'立即生成',
    task_list:'任务列表',
    Creation_Record:'创作记录',
    Loading_images:'Loading image',
    delete:'删除',
    share:'分享',
    Cancel_sharing:'取消分享',
    Copy_prompt_words:'复制提示词',
    no_more_data_left:'没有更多数据了',
    standar:'标准',
    high_definition:'高清',
    vivid:'生动',
    natural:'自然',
    Failed_configuration:'获取系统配置失败',
    ask_failed:'任务执行失败',
    Task_ID:'任务ID',
    ainting_prompt_word:'请输入绘画提示词',
    Task_successful:'任务执行成功',
    Task_failed:'任务执行失败',
    This_operation_operation_code:'此操作将会删除任务和图片，继续操作码?',
    Delete_prompt:'删除提示',
    confirm:'确认',
    cancellation:'取消',
    Task_deleted_successfully:'任务删除成功',
    Task_deletion_failed:'任务删除失败',
    Image_publishing:'图片发布',
    UnpublishL:'取消发布',
    successsed:'成功',
    failed:'失败',
    Failed_configuration:'获取系统失败',
    Failed_system_menu:'获取系统菜单失败',
    Creative_Center:'创作中心',
    Generate_size_image:'Generate_size_image',
    picture_scale:'图片比例',
    Image_quality:'图片画质',
    Please_select:'请选择',
    generated_image:'生成的图片质量，质量越好出图越慢',
    model_selection:'模型选择',
    Partial_Model:'偏真实通用模型',
    Animation_model:'Animation_model',
    Repeat_tiling:'重复',
    Parameter_definition:'参数释义',
    raw_mode: '原始模式',
    Enable_RAW_mode: '启用新的RAW模式',
    portrayal_characters: "呈现的人物写实感更加逼真，人物细节、光源、流畅度也更加接近原始作品",
    need_to_add_prompts:'同时也意味着您需要添加更长的提示',
    Creativity:'创意度',
    Parameter_Usage:'参数用法',
    perhaps:'或',
    Value_range:"取值范围",
    higher_value:'取值越高结果越发散，反之则稳定收敛',
    range:'范围',
    Default_value_100:'默认值100',
    High_values_artistic_images_relevance_prompts:'高取值会产生非常艺术化但与提示关联性较低的图像',
    random_seed:'random seed',
    default_value_random_generation:'默认值0表示随机产生',
    Using_seed_parameters_produce_similar_images:'使用相同的种子参数和描述将产生相似的图像',
    Wen_Sheng_Tu:'Wen_Sheng_Tu:',
    cue_word:'提示词',
    contentyou_want:'输入你想要的内容，用逗号分割',
    enter_drawing_prompt:'请在此输入绘画提示词，系统会自动翻译中文提示词，高手请直接输入英文提示词',
    Unwanted_content:'不希望出现的内容：（可选）',
    Elements_image:'不想出现在图片上的元素(例如：树，建筑)',
    content_the_image:'请在此输入你不希望出现在图片上的内容，系统会自动翻译中文提示词',
    Graph_generation_diagram:'图生图',
    Pictography_Using_reference_generating_images_supporting_format_images:'图生图：以某张图片为底稿参考来创作绘画，生成类似风格或类型图像，支持 PNG 和 JPG 格式图片',
    Reference_weight:'参考权重',
    Use_parameter_importance_URLs:'使用图像权重参数--iw来调整图像 URL 与文本的重要性',
    weigh_high:'权重较高时意味着图像提示将对完成的作业产生更大的影响',
    enter_automatically_translate:'请在此输入绘画提示词，系统会自动翻译中文提示词，高手请直接输入英文提示词',
    Unwanted_content:'不希望出现的内容：（可选)',
    Rongtu:'Rongtu',
    uploa_images:'请上传两张以上的图片，最多不超过五张，超过五张图片请使用图生图功能',
    upload_faces_right_image:'请上传两张有脸部的图片，用左边图片的脸替换右边图片的脸',
    face_transplant:'换脸',
    consistence:'一致性',
    Role_consistency:'角色一致性',
    Note_support_consistency:'注意：只有于 niji6 和 v6 模型支持一致性功能，如果选择其他模型此功能将会生成失败',
    enter_image_upload_image:'请输入图片URL或者上传图片',
    Value_range:'取值范围 0-100 ',
    Default_value_clothing:'默认值100参考原图的脸部、头发和衣服',
    means_only_changing:'0则表示只换脸',
    Consumption_drawing:'每次绘图消耗',
    Operation_consumption:'操作消耗',
    Current_computing:'当前可用算力',
    Downloading_images:'正在下载图片',
    Realistic_mode:'写实模式',
    High_quality:'优质模式',
    Unreal_Mode:'虚幻模式',
    real_mode:'真实模式',
    Anime_style:'动漫风',
    lovely:'可爱',
    landscape:'风景',
    default:'默认',
    ordinary:'普通',
    clearness:'清晰',
    high0_definition:'高清',
    Task_execution_failed:'任务执行失败',
    Task_ID:'任务ID',
    reason:'原因',
    Failed_task:'获取任务失败',
    Upload_successful:'上传成功',
    Upload_failed:'上传失败',
    Please_enter_word:'请输入绘画提示词',
    Anime_models_enabled:'动漫模型不允许启用原始模式',
    changing_operation_requires:'换脸操作需要上传两张图片',
    painting_task_pushed:'绘画The task was pushed successfully. Please wait patiently for the task to be executed...',
    Task_push_failed:'任务推送失败',
    push_successful:'任务推送成功，请耐心等待任务执行',
    This_operation_continue:'此操作将会删除任务和图片，继续操作吗?',
    Delete_prompt:'删除提示',
    Task_deleted_successfully:'任务删除成功',
    Task_deletion_failed:'任务删除失败',
    Image_publishing:'发布图片',
    Unpublish:'取消发布',
    Sampling_method:'采样方法',
    Euler_better_effects:'出图效果比较好的一般是 Euler 和 DPM 系列算法',
    image_size:'图片尺寸',
    Iteration_Steps:'迭代步数',
    larger_value:'值越大则代表细节越多，同时也意味着出图速度越慢',
    Guiding_coefficient:"引导系数",
    Guidance_coefficient_prompt_words:'提示词引导系数，图像在多大程度上服从提示词:',
    Lower_creative_results:'较低值会产生更有创意的结果',
    random_factor:'随机因子',
    Random_seed_result:'随机数种子，相同的种子会得到相同的结果',
    randomly_generate_seeds:'设置为 -1 则每次随机生成种子',
    Using_Random_Numbers:'使用随机数',
    HD_repair:'高清修复',
    Generate_resolution_method_images:'先以较小的分辨率生成图像，接着方法图像',
    Redraw_amplitude:'重绘幅度',
    Determining_content_creative_image:'决定算法对图像内容的影响程度<br />较大的值将得到越有创意的图像',
    Amplification_algorithm:'放大算法',
    High_definition_amplification:'高清修复放大算法,主流算法有Latent和ESRGAN_4x',
    magnification:'放大倍数',
    Random_number_result:'随机数种子，相同的种子会得到相同的结果',
    Set_randomly_generate:'设置为 -1 则每次随机生成种子',
    Redraw_number_iteration_original:'重绘迭代步数,如果设置为0,则设置跟原图相同的迭代步数',
    Reverse_Words:'反向提示词',
    Unwanted_style_below:'不希望出现的元素，下面给了默认的起手式',
    Single_consumption:'单次绘图消耗',
    currently_available:' 当前可用',
    Generate_Now:'立即生成',
    The_task_queued:'任务正在排队中',
    Creation_Record:'创作记录',
    Task_details:'任务详情弹框',
    Painting_details:'绘画任务详情',
    Positive_words:'正向提示词',
    Reverse_Words:'反向提示词',
    Sampling_method:'采样方法',
    Draw_style:'画一张同款的',
    AI_Wall:'AI 绘画作品墙',
    Copy_prompt_words:'复制提示词',
    Draw_same_style:'画同款',
    Draw_picture_style:'画一张同款的',
    Image_acquisition_failed:'获取图片失败',
    Deployment_Documents:'部署文档',
    Project_source_code:'项目源码',
    log_on:"登录",
    register:'注册',
    Welcome_use:'欢迎使用:',
    AI_dialogue:'AI对话',
    draw:'绘画',
    Mind_map:'思维导图',
    As_generation_first:'作为我们这一代人，我们很荣幸能首先做到这一点。让我们先与您一起使用人工智能',
    Membership_promotion_plan:'会员推广计划',
    We_registering_receive:'我们热烈欢迎您与您周围的朋友分享此应用程序。成功注册后，您将收到',
    The_conversation:'次对话额度以及',
    Secondary_reward:'次AI绘画额度作为奖励',
    You_exclusive_WeChat_friends:' 你可以保存下面的二维码或者直接复制分享您的专属推广链接发送给微信好友',
    copy_link:'复制链接',
    Hits:'点击量',
    Registration_volume:'注册量',
    Conversion_rate:'转化率',
    You_recommend_users:'您推荐用户',
    Failed_invitation_code:'获取邀请码失败',
    account_number:'账号',
    password:'密码',
    reset_password:'重置密码',
    home_page:'首页',
    enter_name:'请输入用户名',
    Please_password:'请输入密码',
    Login_failed:'登录失败',
    Mind_Center:'思维导图创作中心',
    Your_needs:'你的需求?',
    Please_improve:'请给AI输入提示词，让AI帮你完善',
    Please_maps:'请选择生成思维导图的AI模型',
    Please_model:'请选择模型',
    Current_power:'当前可用算力',
    Intelligent_maps:'智能生成思维导图',
    Generate_content:'使用已有内容生成',
    Please_syntax:'请用markdown语法输入您想要生成思维导图的内容',
    Directly_generated:'直接生成（免费）',
    Download_images:'下载图片',
    assistant:'助手',
    complet_box:'完整的开源系统，前端应用和后台管理系统皆可开箱即用。',
    Based_experience:'基于 Websocket 实现，完美的打字机体验。',
    Equipped_application_needs:'内置了各种预训练好的角色应用,轻松满足你的各种聊天和应用需求',
    Support_models:'支持 OPenAI，Azure，文心一言，讯飞星火，清华 ChatGLM等多个大语言模型。',
    Diffusion_painting_box:'支持 MidJourney / Stable Diffusion AI 绘画集成，开箱即用。',
    Support_enterprise_channels:'支持使用个人微信二维码作为充值收费的支付渠道，无需企业支付通道。',
    It_payment_purchase:"已集成支付宝支付功能，微信支付，支持多种会员套餐和点卡购买功能。",
    Integrate_language_models:'集成插件 API 功能，可结合大语言模型的 function 功能开发各种强大的插件',
    Failed_model:'获取模型失败',
    Please_requirements:'请输入你的需求',
    Member_Center:'会员充值中心',
    Change_password:'修改密码',
    Change_account:'更改账号',
    Join_Crowdfunding:'加入众筹',
    Crowdfunding_verification:'众筹核销',
    Log_in:'退出登录',
    Original_goods:'商品原价',
    illustrate:'说明',
    period_validity:'有效期',
    long_term:'long-term',
    Alipay:'支付宝',
    Computational_value:'算力值',
    WeChat:'微信',
    Consumer_bills:'消费账单',
    Participate_crowdfunding:'参与众筹',
    currently_computing_power:'您好，目前每单位算力众筹价格为',
    Since_please_manually_transfer_payment:'由于本人没有开通微信支付，付款后请凭借转账单号,点击【众筹核销】按钮手动核销。',
    Paid_amount:'实收金额',
    Please_phone:'请打开你的手机',
    Scan_code_payment:'扫码支付',
    Failed_package:'获取产品套餐失败',
    Failed_payment_method:'获取支付方式失败',
    Failed_payment_order:'生成支付订单失败',
    Scan_make_payment:'扫码成功，请在手机上进行支付',
    ayment_successful_page:'支付成功，正在刷新页面',
    Failed_status:'Failed_status:',
    Consumption_log:'消费日志',
    model:'模型',
    user:'用户',
    Start_date:'开始日期',
    End_date:'结束日期',
    search_for:'搜索',
    amount:'数额',
    types_of:'类型',
    balance:'余额',
    occurrence_time:'发生时间',
    notes:'备注',
    Data_acquisition_failed:'获取数据失败',
    Mobile_registration:'手机注册',
    phone_number:'手机号码',
    code:'验证码',
    Email_registration:'邮箱注册',
    address:'邮箱地址',
    User_Registration:'用户名注册',
    Please_password:'请输入密码(8-16位)',
    Duplicate_password:'重复密码(8-16位)',
    Invitation_code:'邀请码(可选)',
    The_disabled:'注册功能已关闭',
    Sorry_registration:'抱歉，系统已关闭注册功能，请联系管理员添加账号',
    User_Registration:'用户注册',
    enter_name:'请输入用户名',
    Please_number:'请输入合法的手机号',
    Please_email_address:'请输入合法的邮箱地址',
    The_characters:'密码的长度为8-16个字符',
    The_inconsistent:'两次输入密码不一致',
    Please_code:'请输入验证码',
    Registration_interface:'注册成功，即将跳转到对话主界面',
    login_has_failed:'注册失败',
    //-----------welcome---
    'Small scale chopper':'小试牛刀',
    'Plugin Enhancement':'插件增强',
    'Capability Expansion':'能力扩展',
    //-----------chatPlus---
    'Search Sessions':'搜索会话',

  }