export default {    
    world_moment:'Forgive the world for a moment, but still haven\'t found the page you\'re looking for', 
    AI_Assistant: 'AI Assistant Application Center',
    use:'use',
    remove:'remove',
    Add_application:'Add application',
    Failed_application:'Failed application',
    Add_application:'Add an application',
    Remove_App:'Remove App',
    successed:'successed',
    failed:'failed',
    Export_PDF_document:'Export PDF document',
    Failed_history:'Failed history',
    Loading_fail:'Loading fail',
    Yahei:'Yahei',
    Enter_title:'Enter title',
    edit:'edit',
    Delete_Session:'Delete Session',
    Account_information:'Account information',
    Clear_sessions:'Clear sessions',
    log_off:'log off:',
    model:'model',
    Create_conversation:'Create conversation',
    Export_Session:'Export Session',
    Stop_generating:'Stop generating',
    regeneration:'regeneration',
    Press_line:'Press line',
    Website_Announcement:'Website Announcement',
    ChatGPT_Assistant:'ChatGPT Assistant',
    Failed_configuration:'Failed to obtain system configuration',
    Replicating_Success:'Replicating Success',
    copy_failed:'copy failed',
    Failed_role:'Failed role',
    Failed_model:'Failed_model',
    Failed_session_list:'Failed session list',
    Please_title:'Please title',
    The_ID:'The ID',
    operation_failed:'operation failed',
    Conversation_successful:'Conversation successful',
    AI_answering:'AI is answering, please wait',
    session_history_records:'session history records',
    Operation_prompt:'Operation prompt',
    confirm:'confirm',
    cancellation:'cancellation',
    Operation_successful:'Operation successful',
    Operation_failed:'Operation failed',
    Unregistration_failed:'Unregistration failed',
    Failed_history:'Failed history',
    Regenerate_questions:'Regenerate questions',
    select_session:'select_session',
    Creative_Center:'Creative Center',
    Picture_quality:'Picture quality',
    image_size:'image size',
    Vividness_makes_model:'Vividness makes model',
    Please_enter_directly:'Please enter directly',
    Consumption_drawing:'Consumption per drawing',
    computing_power:'computing power',
    currently_available:'currently available',
    The_queued:'The task is currently queued',
    Generate_Now:'Generate Now',
    task_list:'task list',
    Creation_Record:'Creation Record',
    Loading_images:'Loading images',
    delete:'delete',
    share:'share',
    Cancel_sharing:'Cancel sharing',
    Copy_prompt_words:'Copy prompt words',
    no_more_data_left:'no more data left',
    standar:'standar',
    high_definition:'high definition',
    vivid:'vivid',
    natural:'natural',
    Failed_configuration:'Failed to obtain system configuration',
    Task_failed:'Task execution failed',
    Task_ID:'Task ID',
    ainting_prompt_word:'painting prompt word',
    Task_successful:'Task successful',
    Task_failed:'Task failed',
    This_operation_operation_code:'This operation operation code',
    Delete_prompt:'Delete prompt',
    confirm:'confirm',
    cancellation:'cancellation',
    Task_deleted_successfully:'Task deleted successfully',
    Task_deletion_failed:'Task deletion failed',
    Image_publishing:'Image publishing',
    Unpublish:'Unpublish',
    successed:'successed',
    failed:'failed',
    Failed_configuration:'Failed configuration',
    Faile_system_menu:'Failed to obtain system menu',
    Creative_Center:'Creative Center',
    Generate_size_image:'Generate_size_image',
    picture_scale:'picture scale',
    Image_quality:'Image quality',
    Please_select:'Please select',
    generated_image: 'generated image, slower output',
    model_selection:'model selection',
    Partial_Model:'Partial Model',
    Animation_model:'Animation model',
    Repeat_tiling:'Repeat tiling',
    Parameter_definition:'Parameter definition',
    raw_mode:'raw mode',
    Enable_RAW_mode:'Enable RAW mode',
    portrayal_characters:'portrayal characters,details, lighting,  original work',
    need_to_add_prompts:'need to add prompts',
    Creativity:'Creativity',
    Parameter_Usage:'Parameter Usage',
    perhaps:'perhaps',
    Value_range:'Value range',
    higher_value:' higher value,divergent result, otherwise converge stably',
    range:'range',
    Default_value_100:'Default value 100',
    High_values_artistic_images_relevance_prompts:'High values artistic images relevance prompts',
    random_seed:'random seed',
    default_value_random_generation:'The default value of 0 indicates random generation',
    Using_seed_parameters_produce_similar_images:'Using the same seed parameters and descriptions will produce similar images',
    Wen_Sheng_Tu:'Wen Sheng Tu',
    cue_word:'cue word',
    contentyou_want:'Enter the content you want, separated by commas',
    enter_drawing_prompt:'Please enter the drawing prompt words here, and the system will automatically translate the Chinese prompt words. For experts, please enter the English prompt words directly',
    Unwanted_content:'Unwanted content: (optional)',
    Elements_image:'Elements that do not want to appear in the image (such as trees, buildings)',
    content_the_image:'Please enter the content you do not want to appear on the image here, and the system will automatically translate Chinese prompts',
    Graph_generation_diagram:'Graph generation diagram',
    Pictography_Using_reference_generating_images_supporting_format_images:'Pictography: Using a certain image as a reference for creating a painting, generating images of similar styles or types, supporting PNG and JPG format images',
    Reference_weight:'Reference weight',
    Use_parameter_importance_URLs:'Use image weight parameter - iw to adjust the importance of image URLs and text',
    weigh_high:'When the weight is high, it means that the image prompts will have a greater impact on the completed task',
    enter_automatically_translate:'Please enter the drawing prompt words here, and the system will automatically translate the Chinese prompt words. For experts, please enter the English prompt words directly',
    Unwanted_content:'Unwanted content: (optional)',
    Rongtu:'Rongtu',
    uploa_images:'Please upload two or more images, up to a maximum of five. If there are more than five images, please use the image generation function',
    upload_faces_right_image:'Please upload two images with faces and replace the face in the right image with the face in the left image',
    face_transplant:'face transplant',
    consistence:'consistence',
    Role_consistency:'Role consistency',
    Note_support_consistency:'Note: Only the Niji6 and v6 models support consistency functionality. If other models are selected, this feature will generate a failure',
    enter_image_upload_image:'Please enter the image URL or upload the image',
    Value_range:'Value range: 0-100',
    Default_value_clothing:'Default value of 100 refers to the face, hair, and clothing in the original image',
    means_only_changing:'0 means only changing faces',
    Consumption_drawing:'Consumption per drawing',
    Operation_consumption:'Operation consumption',
    Current_computing:'Current available computing power',
    Downloading_images:'Downloading images',
    Realistic_mode:'Realistic mode',
    High_quality:'High quality mode',
    Unreal_Mode:'Unreal Mode',
    real_mode:'real mode',
    Anime_style:'Anime style',
    lovely:'lovely',
    landscape:'landscape',
    default:'default',
    ordinary:'ordinary',
    clearness:'clearness',
    high_definition:'high definition',
    Task_execution_failed:'Task execution failed',
    Task_ID:'Task ID',
    reason:'reason',
    Failed_task:'Failed to obtain task',
    Upload_successful:'Upload successful',
    Upload_failed:'Upload failed',
    Please_enter_word:'Please enter the painting prompt word',
    Anime_models_enabled:'Anime models do not allow original mode to be enabled',
    changing_operation_requires:'The face changing operation requires uploading two images',
    painting_task_pushed:'The painting task has been successfully pushed, please be patient and wait for the task to be executed',
    Task_push_failed:'Task push failed',
    push_successful:'Task push successful, please be patient while the task is executed...',
    This_operation_continue:'This operation will delete tasks and images. Do you want to continue?',
    Delete_prompt:'Delete prompt',
    Task_deleted_successfully:'Task deleted successfully',
    Task_deletion_failed:'Task deletion failed',
    Image_publishing:'Image publishing',
    Unpublish:'Unpublish',
    Sampling_method:'Sampling method',
    Euler_better_effects:'The Euler and DPM series algorithms generally have better graphics effects',
    image_size:'image size',
    Iteration_Steps:'Iteration Steps',
    larger_value:'The larger the value, the more details it represents, and it also means that the drawing speed is slower',
    Guiding_coefficient:'Guiding coefficient',
    Guidance_coefficient_prompt_words:'Guidance coefficient for prompt words, to what extent does the image comply with prompt words',
    Lower_creative_results:'Lower values will produce more creative results',
    random_factor:'random factor',
    Random_seed_result:'Random number seeds, the same seed will yield the same result',
    randomly_generate_seeds:'Set to -1 to randomly generate seeds each time',
    Using_Random_Numbers:'Using Random Numbers',
    HD_repair:'HD repair',
    Generate_resolution_method_images:'Generate images at a smaller resolution first, followed by method images',
    modify_details_composition:'Then modify the details without changing the composition',
    Redraw_amplitude:'Redraw amplitude',
    Determining_content_creative_image:'Determining the degree of influence of algorithms on image content<br/>A larger value will result in a more creative image',
    Amplification_algorithm:'Amplification algorithm',
    High_definition_amplification:'High definition repair amplification algorithm, mainstream algorithms include Latent and ESRGAN_4x',
    magnification:'magnification',
    Random_number_result:'Random number seeds, the same seed will yield the same result',
    Set_randomly_generate:'Set to -1 to randomly generate seeds each time',
    Redraw_number_iteration_original:'Redraw the number of iteration steps. If set to 0, set the same number of iteration steps as the original image',
    Reverse_Words:'Reverse Hint Words',
    Unwanted_style_below:'Unwanted elements are given the default starting style below',
    Single_consumption:'Single drawing consumption',
    currently_available:'currently available',
    Generate_Now:'Generate Now',
    The_task_queued:'The task is currently queued',
    Creation_Record:'Creation Record',
    Task_details:'Task details pop-up',
    Painting_details:'Painting task details',
    Positive_words:'Positive prompt words',
    Reverse_Words:'Reverse Hint Words',
    Sampling_method:'Sampling method',
    Draw_style:'Draw a picture of the same style',
    AI_Wall:'AI Painting Wall',
    Copy_prompt_words:'Copy prompt words',
    Draw_same_style:'Draw the same style',
    Draw_picture_style:'Draw a picture of the same style',
    Image_acquisition_failed:'Image acquisition failed',
    Deployment_Documents:'Deployment Documents',
    Project_source_code:'Project source code',
    log_on:'log on',
    register:'register',
    Welcome_use:'Welcome to use',
    AI_dialogue:'AI dialogue',
    draw:'draw',
    Mind_map:'Mind map',
    As_generation_first:'As our generation, we are honored to be the first to achieve this. Let us first use artificial intelligence with you',
    Membership_promotion_plan:'Membership promotion plan',
    We_registering_receive:'We warmly welcome you to share this application with your friends around you. After successfully registering, you will receive',
    The_conversation:'The quota for each conversation and',
    Secondary_reward:'Secondary AI painting quota as a reward',
    You_exclusive_WeChat_friends:'You can save the QR code below or simply copy and share your exclusive promotion link and send it to your WeChat friends',
    copy_link:'copy link',
    Hits:'Hits',
    Registration_volume:'Registration volume',
    Conversion_rate:'Conversion rate',
    You_recommend_users:'You recommend users',
    Failed_invitation_code:'Failed to obtain invitation code',
    account_number:'account number',
    password:'password',
    reset_password:'reset password',
    home_page:'home page',
    enter_name:'enter one user name',
    Please_password:'Please enter password',
    Login_failed:'Login failed',
    Mind_Center:'Mind Map Creation Center',
    Your_needs:'Your needs?',
    Please_improve:'Please input prompt words for AI to help you improve',
    Please_maps:'Please select the AI model for generating mind maps',
    Please_model:'Please select a model',
    Current_power:'Current available computing power',
    Intelligent_maps:'Intelligent generation of mind maps',
    Generate_content:'Generate using existing content?',
    Please_syntax:'Please enter the content you want to generate a mind map using the markup syntax',
    Directly_generated:'Directly generated (free)',
    Download_images:'Download images',
    assistant:'assistant',
    complet_box:'A complete open-source system, both front-end applications and back-end management systems can be used out of the box.',
    Based_experience:'Based on Websocket implementation, a perfect typewriter experience.',
    Equipped_application_needs:'Equipped with various pre trained character applications, it easily meets your various chat and application needs.',
    Support_models:'Support OPenAI, Azure, ERNIE Bot, iFLYTEK Spark, Tsinghua ChatGLM and other large language models..',
    Diffusion_painting_box:'Supports MidJourney/Stable Diffusion AI painting integration, ready to use out of the box.',
    Support_enterprise_channels:'Support the use of personal WeChat QR codes as payment channels for recharging fees, without the need for enterprise payment channels.',
    It_payment_purchase:'It has integrated Alipay payment function, WeChat payment, and supports multiple member packages and card purchase functions.',
    Integrate_language_models:'Integrate plugin API functionality and develop various powerful plugins by combining the function functions of large language models.',
    Failed_model:'Failed to obtain model',
    Please_requirements:'Please enter your requirements',
    Member_Center:'Member Recharge Center',
    Change_password:'Change password',
    Change_account:'Change account',
    Join_Crowdfunding:'Join Crowdfunding',
    Crowdfunding_verification:'Crowdfunding verification',
    Log_in:'Log out and log in',
    Original_goods:'Original price of goods',
    illustrate:'illustrate',
    period_validity:'period of validity',
    long_term:'long-term',
    Alipay:'Alipay',
    Computational_value:'Computational power value',
    WeChat:'WeChat',
    Consumer_bills:'Consumer bills',
    Participate_crowdfunding:'Participate in crowdfunding',
    currently_computing_power:'Hello, currently the crowdfunding price per unit of computing power is',
    Since_please_manually_transfer_payment:'Since I have not activated WeChat payment, please manually cancel the payment by clicking the "Crowdfunding Verification" button with the transfer number after payment.',
    Paid_amount:'Paid-in amount',
    Please_phone:'Please open your phone',
    Scan_code_payment:'Scan code payment',
    Failed_package:'Failed to obtain product package',
    Failed_payment_method:'Failed to obtain payment method',
    Failed_payment_order:'Failed to generate payment order',
    Scan_make_payment:'Scan successful, please make payment on your phone',
    Payment_successful_page:'Payment successful, refreshing page',
    Failed_status:'Failed to query payment status',
    Consumption_log:'power-log',
    model:'model',
    user:'user',
    Start_date:'Start date',
    End_date:'End date',
    search_for:"search for",
    amount:'amount',
    types_of:'types of',
    balance:'balance',
    occurrence_time:'occurrence time',
    notes:'notes',
    Data_acquisition_failed:'Data acquisition failed',
    Mobile_registration:'Mobile registration',
    phone_number:'phone number',
    code:'code',
    Email_registration:'Email registration',
    address:'e-mail address',
    User_Registration:'User Name Registration',
    Please_password:'Please enter the password (8-16 digits)',
    Duplicate_password:'Duplicate password (8-16 digits)',
    Invitation_code:'Invitation code (optional)',
    The_disabled:'The registration function has been disabled',
    Sorry_registration:'Sorry, the registration function has been disabled in the system. Please contact the administrator to add an account',
    User_Registration:'User Registration',
    enter_name:'enter one user name',
    Please_number:'Please enter a valid phone number',
    Please_email_address:'Please enter a valid email address',
    The_characters:'The length of the password is 8-16 characters',
    The_inconsistent:'The passwords entered twice are inconsistent',
    Please_code:'Please enter the verification code',
    Registration_interface:'Registration successful, about to jump to the main dialogue interface',
    login_has_failed:'login has failed',
    //-----------welcome---
    'Small scale chopper':'Small scale chopper',
    'Plugin Enhancement':'Plugin Enhancement',
    'Capability Expansion':'Capability Expansion',
     //-----------chatPlus---
    'Search Sessions':'Search Sessions',
}