import { createI18n } from 'vue-i18n';
import messages from './index';

const i18n = createI18n({
    locale: 'en', // 设置默认语言 zh-CN
    fallbackLocale: 'en', // 设置后备语言
    messages, // 设置翻译信息
  });

export default i18n  
